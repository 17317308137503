<template>
  <apexchart height="280"  type="area" :options="options" :series="series"></apexchart>
  
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  props:["series"],
  data() {
    let options = {
      chart: {
        id: "chart2",
        type: "area",
        height: 230,
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#3F7BB6"],
      stroke: {
        width: 3,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 5,
        colors: ["#3F7BB6"],
        strokeWidth: 1,
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    };
    return {
      options,
    };
  },
};
</script>
