<template>
  <div>
    <line-graph :series="formatSeries(buyer.produce)"></line-graph>
      <b-button variant="link" @click="loadProduce" class="float-right">
        <span class="text-primary">Reload Produce</span>
      </b-button>
    <b-table
      id="ProduceHistoryTable"
      :items="buyer.produce"
      :fields="fields"
      show-empty
      empty-text="No produce bought yet"
      :busy="loading"
    >
      
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(farmer_name)="data">
        <div>{{data.item.farmer_name}}</div>
        <div class="text-muted">{{data.item.agent_name}}</div>
      </template>

    </b-table>
  </div>
</template>
<script>
import moment from "moment";
import LineGraph from "./components/LineGraph.vue";
import * as api from "../../api";
import { bus } from '../../../../../../../../main';
export default {
  components: { LineGraph },
  computed:{
    /**
     * @returns {import("../../../../api").Buyer}
     */
    buyer(){
      return this.$store.getters["buyera/getProfile"];
    }
  },
  data() {
    return {
      buyer_id: null,
      loading: true,
      fields: [
        { key: "farmer_name", label: "Name" },
        { key: "value_chain", label: "Crop" },
        {key:"quantity_supplied",label:"Qty"},
        {key:"unit_price",label:"Unit Price"},
        {key:"amount_used",label:"Amount"},
        {
          key: "transaction_date",
          label: "Date",
          formatter: (dt) => {
            return moment(dt).format("YYYY-MM-DD");
          },
        },
      ],
    };
  },
  created() {
    this.buyer_id = this.$route.params["buyer_id"];
  },
  mounted() {
    this.buyer.produce.length?this.loading=false:""
    bus.$on("buyer-loaded",()=>{
      this.loading=false;
    });

  },
  methods: {
    loadProduce() {
      this.loading = true;
      api.produce(this.buyer_id).then((data) => {
        this.$store.dispatch("buyera/updateProduce",data);
        this.loading = false;
      });
    },
    /**
     * @param {Array<import("../../../../api").Produce>} produce
     */
    formatSeries(produce){
      return [{data:produce.map(el=>({x:el.transaction_date,y:el.quantity_supplied}))}];
    }
  },
};
</script>
